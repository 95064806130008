import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@/model/enum';
import EnviromentIndexService from '../service/enviroment-index';
import { download } from '@/service/request';
import { generateUUID } from '@/utils/base_util';
import { dateFormat } from '@/filter';
import { changeTreeDataFieldName, getTreeIdByDepth } from '../data-factory';
import { ChartLineExtend } from '@/mixins/report-chart-base';

export class EnvTemperatureHumidityQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.ENVIROMENT,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        required: false
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_24_HOUR();

    toService() {
        let endTime = this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime();
        const currentTime = new Date().getTime();
        if (endTime > currentTime) {
            endTime = currentTime;
        }
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].toDate().getTime(),
            endTime
        };
    }
}

export async function EnvTemperatureHumidityService(model: EnvTemperatureHumidityQueryModel) {
    const res = await EnviromentIndexService.getTemperatureHumidityCount(model);
    const chartOptions = {
        title: '温湿度统计',
        data: res.data,
        settings: res.settings,
        extend: ChartLineExtend,
        // yAxis: res.yAxis,
        tableRowKey: (record) => {
            return record.locationId || generateUUID();
        },
        tableExpandedRowKeys: getTreeIdByDepth(res.response, 'locationId', 2),
        tableRows: changeTreeDataFieldName(res.response, 'data', 'children'),
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return dateFormat(record.ts) || record.locationName;
                }
            },
            {
                title: '湿度',
                dataIndex: 'humidity'
            },
            {
                title: '温度',
                dataIndex: 'temperature'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnvTemperatureHumidityExportService(queryModel: EnvTemperatureHumidityQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/hts/export/temperatureHumidity`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
