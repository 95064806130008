import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@/model/enum';
import EnviromentIndexService from '../service/enviroment-index';
import { download } from '@/service/request';

export class EnvAirQualityQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.ENVIROMENT,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationIds: Array<string> = undefined;

    toService() {
        return {
            locationIds: this.locationIds
        };
    }
}

export async function EnvAirQualityService(model: EnvAirQualityQueryModel) {
    const res = await EnviromentIndexService.getAirQualityCountData(model);
    const chartOptions = {
        title: '空气质量指标',
        data: res,
        settings: { type: 'histogram' },
        tableRowKey: 'indicator',
        tableColumns: _.map(res.columns, item => {
            return {
                title: item === 'indicator' ? '指标' : item,
                dataIndex: item
            };
        })
    };
    return assignDefaultExtend(chartOptions);
}

export async function EnvAirQualityExportService(queryModel: EnvAirQualityQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/rts/export/airQualityIndex`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
