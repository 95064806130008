import { get, post } from '@/service/request';
import { dateFormat } from '@/filter';
import { EnvAirQualityQueryModel } from '../model/env-air-quality-model';
import { timeTransformData } from '../data-factory';
import { getDateFormatByPeriodTypeEnum } from '@/filter/period-type';
import { EnvTemperatureHumidityQueryModel } from '../model/env-temperature-humidity-model';
import { PropertyEntityType } from '@/model/enum';
import CommonService from '@/service/common';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/envSystem`;
class EnviromentIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getAlarmCount() {
        const resStatusData = await get(`${URL_PATH}/alarm/rts/STATUS/count`);
        const resTotalData = await get(`${URL_PATH}/alarm/rts/count`);
        return {
            totalCount: resTotalData.totalCount,
            unsolvedCount: resStatusData.unsolvedCount
        };
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 空气质量指标统计
     * @returns
     */
    async getAirQualityCountData(queryModel?: EnvAirQualityQueryModel) {
        const url = `${URL_PATH}/facility/rts/airQualityIndex`;
        const res = await post(url, queryModel?.toService());
        const columns = [
            'indicator',
            ..._.map(_.get(res, '[0].data'), item => {
                return item.locationName;
            })
        ];
        return {
            columns,
            rows: _.map(res, item => {
                const rowItem = { indicator: item.indicator };
                for (let i = 1; i < columns.length; i++) {
                    rowItem[columns[i]] = _.get(_.find(item.data, dItem => dItem.locationName === columns[i]), 'value') || 0;
                }
                return rowItem;
            })
        };
    }

    /**
     * 温湿度统计
     * @returns
     */
    async getTemperatureHumidityCount(queryModel?: EnvTemperatureHumidityQueryModel) {
        const url = `${URL_PATH}/facility/hts/temperatureHumidity`;
        const res = await post(url, queryModel?.toService());
        const columns = [
            { code: 'ts', name: '时间' },
            { code: 'humidity', name: '湿度' },
            { code: 'temperature', name: '温度' }
        ];
        const data = timeTransformData(res, columns, getDateFormatByPeriodTypeEnum(), 'locationName');
        return {
            response: res,
            data,
            settings: {
                type: 'line',
                area: true,
                yAxisType: ['normal', 'percent'],
                yAxisName: ['℃', ''],
                axisSite: { right: _.filter(_.get(data, 'columns'), item => item.indexOf('湿度') > -1) }
            }
            // extend: {
            //     yAxis: [
            //         {
            //             splitLine: {
            //                 lineStyle: {
            //                     type: 'solid'
            //                 }
            //             }
            //         },
            //         {
            //             show: true,
            //             splitLine: {
            //                 show: true,
            //                 lineStyle: {
            //                     type: 'dashed'
            //                 }
            //             }
            //         }
            //     ]
            // }
            // yAxis: {
            //     name: '℃',
            //     splitLine: {
            //         show: true,
            //         lineStyle: {
            //             type: 'dashed'
            //         }
            //     }
            // }
        };
    }

    /**
     * 设备状态统计
     * @returns
     */
    async getFacilityStatusCount(params?: any) {
        const url = `${URL_PATH}/facility/rts/statusCountByLocation`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['locationName', 'onlineCount', 'offlineCount'],
                rows: res
            },
            settings: {
                labelMap: { onlineCount: '在线', offlineCount: '离线' },
                stack: { stackCount: ['onlineCount', 'offlineCount'] }
            },
            extend: {
                series: {
                    label: {
                        show: true,
                        color: '#fff',
                        position: 'inside'
                    }
                }
            }
        };
    }

    /**
     * 告警分布统计
     * @returns
     */
    async getAlarmLocationCount(params?: any) {
        const url = `${URL_PATH}/alarm/rts/countByLocation`;
        const res = await get(url, params);
        return {
            data: {
                columns: ['locationName', 'solvedCount', 'unsolvedCount'],
                rows: res
            },
            settings: {
                labelMap: { solvedCount: '已处理', unsolvedCount: '未处理' },
                stack: { stackCount: ['solvedCount', 'unsolvedCount'] }
            },
            extend: {
                series: {
                    label: {
                        show: true,
                        color: '#fff',
                        position: 'inside'
                    }
                }
            }
        };
    }

    /**
     * 今天告警状态统计
     * @returns
     */
    async getAlarmStatusCount() {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: [
                { name: '已处理', value: res.solvedCount },
                { name: '未处理', value: res.unsolvedCount }
            ]
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount() {
        const url = `${URL_PATH}/alarm/rts/countTrends`;
        const res = await get(url);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 今天告警级别统计
     * @returns
     */
    async getAlarmGradeCount() {
        const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        const url = `${URL_PATH}/alarm/rts/GRADE/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: _.map(_.keys(res), item => {
                return {
                    name: _.get(_.find(eventGradeList, eventGrade => eventGrade.code === item), 'name') || item,
                    value: res[item]
                };
            })
        };
    }
}

export default new EnviromentIndexService();
